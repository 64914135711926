
















import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import AppVue from '@/AppVue.vue';
import ReporterAccessRequestCpt from './components/ReporterAccessRequestCpt.vue';

@Component({
  components: {
    ReporterAccessRequestCpt,
  },
})
export default class ReporterAccessRequestView extends AppVue {}
